.cluster-marker {
    color: #000000;
    background: #ffffff;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
}
