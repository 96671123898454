.ant-typography {
    font-family: 'Roboto', sans-serif;
  }

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
  }

input[type="text"] {
    font-size: 16px;
  }

input[type='text'], input[type='password'], input[type='number'], input[type='search'] textarea {
  font-size: 16px;
  }

.ant-form input[type='search'] {
  font-size: 16px;
  }

.ant-select {
  font-size: 16px;
  }


.white-form > .ant-col-xs-24.ant-form-item-label > label {
    color: #FFFFFF !important;
  }

.white-form > .ant-form-item-label > label .ant-form-item-tooltip {
    color: #FFFFFF !important;
  } 